import React from "react"
import Layout from "../components/_App/layout"
import Seo from "../components/_App/seo"
import Navbar from "../components/_App/Navbar"
import Footer from "../components/_App/Footer"
import TeamStyleTwo from "../components/about/TeamStyleTwo"
import { StaticImage } from "gatsby-plugin-image"
import customerCentric from "../assets/icons/noun_customer_677190.svg"
import rightThing from "../assets/icons/noun_correct_3859905.svg"
import fun from "../assets/icons/noun_fun_1626795.svg"
import CustomerExperience from "../components/about/customeExperience"

const data = [
  {
    id: 1,
    heading: `Customer centric`,
    para: `Our culture places the customer at the center of everything that we do, creating the best experience for the customer.`,
    image: customerCentric,
  },
  {
    id: 2,
    heading: `Always doing the right thing`,
    para: `That means doing what is best for the greater good. We make decisions that are not based just on our own needs. We want our clients and employees to succeed.`,
    image: rightThing,
  },
  {
    id: 3,
    heading: `Having fun as one global team`,
    para: `We have a fun work environment.  Our people have a positive mind-set which energizes people. We encourage our teams to not be limited by the fear of failure.`,
    image: fun,
  },
]
const About = () => (
  <Layout>
    <Seo
      title="About Us"
      keyword=" atyeti, exceeding expectations, team, executive team, experts, global organization"
      description="Atyeti is a global information technology solutions and services provider."
    />
    <Navbar />
    <div className="agency-about-area pt-160">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="agency-about-img d-block d-lg-none">
              <StaticImage
                src="../assets/images/about.png"
                alt="About Us"
                loading="eager"
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12 about-section">
            <div className="agency-about-content">
              {/* <span className="sub-title">About Us</span> */}
              <h2 >ATYETI MEANS – EXCEEDING EXPECTATIONS</h2>
              <div className="bar"></div>
              <p>
                This is how we do it. Having a mindset that “exceeds
                expectations” means that every task or situation is viewed as an
                opportunity to go above and beyond what is expected by all
                stakeholders. This mindset is core to the culture of our
                organization. Our ambition is to always exceed and proactively
                delight our Stakeholders.
              </p>
              <p  className="text-white">
                Atyeti is a global organization. We deliver services across
                different cultures and we strive to keep our company's DNA the
                same across the globe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div className="services-area-two pt-80 pb-50">
        <div className="container">
          <div className="row justify-content-center">
            {data.map(item => (
              <div key={item.id} className="col-lg-4 col-md-6">
                <div className="single-services-box ">
                  <div className="icon">
                    <img src={item.image} alt="service icon" />
                  </div>
                  <h3>{item.heading}</h3>
                  <p>{item.para}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    <TeamStyleTwo />
    <CustomerExperience/>
    <Footer />
  </Layout>
)

export default About
