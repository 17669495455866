import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const CustomerExperience = () => {
  return (
    <div className="team-area repair-team-area pt-80 pb-50 #f5f4f6">
      <div className="container">
        <div className="section-title">
          <h2>The Customer Experience</h2>
          <div className="bar"></div>
          <p>
            Risk mitigation is the ability to provide and maintain acceptable
            level of service in the face of challenges to normal operations
          </p>
        </div>
        <div className="row d-flex justify-content-center ">
          <div className="col-lg-4 d-none d-lg-block">
            <div className="customer-experience-left-box">
              <h6>Resources</h6>
              <p>
                Teams Ready to Go Technical Expertise Skilled Resources Managing
                Employee Turnover
              </p>
            </div>
            <div className="customer-experience-left-box">
              <h6>Agile/QA</h6>
              <p>
                Superior quality product Better control- Reduced risks Improved
                project predictability Increased flexibility Continuous
                improvement
              </p>
            </div>

            <div className="customer-experience-left-box">
              <h6>Domain Expertise</h6>
              <p>
                Good understanding of the Business. Partnership with leading
                companies – GCP, HashiCorp, Finastra, Databricks, Snowflake etc.
              </p>
            </div>
          </div>
          <div className="col-lg-4 customer-experience-image d-flex d-lg-none" >
            <StaticImage
              src="../../assets/images/customer-experience.png"
              alt="Why join Atyeti?"
              height={300}
            />
          </div>
          <div className="col-lg-4 customer-experience-image d-none d-lg-flex" >
            <StaticImage
              src="../../assets/images/customer-experience.png"
              alt="Why join Atyeti?"
            />
          </div>
          <div className="col-lg-4 d-sm-block d-lg-none">
            <div className="customer-experience-right-box">
              <h6>Resources</h6>
              <p>
                Teams Ready to Go Technical Expertise Skilled Resources Managing
                Employee Turnover
              </p>
            </div>
            <div className="customer-experience-right-box">
              <h6>Agile/QA</h6>
              <p>
                Superior quality product Better control- Reduced risks Improved
                project predictability Increased flexibility Continuous
                improvement
              </p>
            </div>

            <div className="customer-experience-right-box">
              <h6>Domain Expertise</h6>
              <p>
                Good understanding of the Business. Partnership with leading
                companies – RedHat, Finastra, Gresham, Curiosity, and Hashi Corp
                etc
              </p>
            </div>
            </div>
          <div className="col-lg-4">
            <div className="customer-experience-right-box">
              <h6>Knowledge Transfer & Management</h6>
              <p>
                Robust Processes to Capture Knowledge R&R mapping at Individual,
                Project and Org Level. Evaluate Early and Often
              </p>
            </div>
            <div className="customer-experience-right-box">
              <h6>Governance and Metrics</h6>
              <p>
                Measure and Report Performance SLA Measurement and Enforcement
                Escalation of Missed Milestones Clear Success Criteria
              </p>
            </div>

            <div className="customer-experience-right-box">
              <h6>Cost Efficiency</h6>
              <p>Global delivery model Managed Services/ Managed Capacity</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CustomerExperience
